import React from "react";
import { withPrefix, Link } from "gatsby";
import styles from "./Author.module.scss";

type Props = {
  author: {
    name: string;
    bio: string;
    photo: string;
  };
  isIndex: boolean | null | undefined;
};

const Author = ({ author, isIndex }: Props) => (
  <div
    className={styles["author"]}
    // @ts-ignore
    itemScope
    itemType="http://schema.org/Person"
  >
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles["author__photo"]}
        width="75"
        height="75"
        alt={author.name}
      />
    </Link>

    {isIndex === true ? (
      <h1 className={styles["author__title"]}>
        <Link className={styles["author__title-link"]} to="/">
          {/*@ts-ignore*/}
          <span itemProp="name">{author.name}</span>
        </Link>
      </h1>
    ) : (
      <h2 className={styles["author__title"]}>
        <Link className={styles["author__title-link"]} to="/">
          {/*@ts-ignore*/}
          <span itemProp="name">{author.name}</span>
        </Link>
      </h2>
    )}
    {/*@ts-ignore*/}
    <p className={styles["author__subtitle"]} itemProp="description">
      {author.bio}
    </p>
  </div>
);

export default Author;
